import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { IRootState } from 'app/shared/reducers';

import { toast } from 'react-toastify';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Link } from 'react-router-dom';

export interface IFooterProps extends StateProps, DispatchProps {}

export const Footer = (props: IFooterProps) => {

  const extraColumn = props.isPatient || props.isStaff || props.isPhysician;

  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className={`${extraColumn ? 'col-lg-3' : 'col-lg-4'} col-lg-4 col-md-6 footer-contact`}>
              <h3>{process.env.REACT_APP_COMPANY}</h3>
              <p>
                {process.env.REACT_APP_ADDRESS}<br />
                <br />
                <strong>Phone:</strong> {process.env.REACT_APP_PHONE}
                <br />
                <strong>Email:</strong> info@{process.env.REACT_APP_HOST}
                <br />
              </p>
            </div>

            <div className={`${extraColumn ? 'col-lg-3' : 'col-lg-4'} col-lg-4 col-md-6 footer-links`}>
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i> <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i> <Link to="/about">About us</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i> <Link to="/departments">Departments</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i> <Link to="/doctors">Doctors</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i> <Link to="#appointment">Make an appointment</Link>
                </li>
              </ul>
            </div>
            {props.isAdmin ? (
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>My Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/patient">Patients</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/physician">Physician</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/staff">Staff</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/appointment">Appointment</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/c-test-item">Test Item</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/room">Room</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/country">Country</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/c-state">State/City</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/c-message">Messages</a>
                  </li>
                </ul>
              </div>
            ) : null}

            {props.isPatient ? (
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>My Links (Patient)</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/patient-appointment">My Appointments</a>
                  </li>
                </ul>
              </div>
            ) : null}

            {props.isStaff ? (
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>My Links (Staff)</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/search-patient">Search Patient</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/ps-in-patient">Inpatients</a>
                  </li>
                </ul>
              </div>
            ) : null}

            {props.isPhysician ? (
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>My Links (Physician)</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/physician-appointment">My Appointments</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/physician-patient">My Patients</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/ps-in-patient">MyInPatients</a>
                  </li>
                </ul>
              </div>
            ) : null}

            <div className={`${extraColumn ? 'col-lg-3' : 'col-lg-4'} col-lg-4 col-md-6 footer-newsletter`}>
              <div className="info">
                <div className="address">
                  <h4>Location:</h4>
                  <div><i className="bi bi-geo-alt"></i>{process.env.REACT_APP_ADDRESS}</div>
                </div>

                <div className="email">
                  <h4>Email:</h4>
                  <div><i className="bi bi-envelope"></i>info@{process.env.REACT_APP_HOST}</div>
                </div>

                <div className="phone">
                  
                  <h4>Call:</h4>
                  <div><i className="bi bi-phone"></i>{process.env.REACT_APP_PHONE}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-md-flex py-4">
        <div className="me-md-auto text-center text-md-start">
          <div className="copyright">
            &copy; Copyright{' '}
            <strong>
              <span>{process.env.REACT_APP_COMPANY}</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits"></div>
        </div>
        <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="#" className="twitter">
            <i className="bx bxl-twitter"></i>
          </a>
          <a href="#" className="facebook">
            <i className="bx bxl-facebook"></i>
          </a>
          <a href="#" className="instagram">
            <i className="bx bxl-instagram"></i>
          </a>
          <a href="#" className="google-plus">
            <i className="bx bxl-skype"></i>
          </a>
          <a href="#" className="linkedin">
            <i className="bx bxl-linkedin"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isPhysician: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PHYSICIAN]),
  isStaff: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.STAFF]),
  isPatient: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PATIENT]),
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
