import React, { useState, useEffect } from 'react';
import { logInfo, Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Input, Row, Col, Alert, Button } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { sendAppoRequest, reset } from './appo.request.reducer';
import { toast } from 'react-toastify';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IRegisterProps extends StateProps, DispatchProps {}

export const RegisterPage = (props: IRegisterProps) => {
  const today = (): string => {
    // Today + 1 day - needed if the current day must be included
    const day: Date = new Date();
    day.setDate(day.getDate() + 1);
    const toDate = new Date(day.getFullYear(), day.getMonth(), day.getDate());
    return toDate.toISOString().slice(0, 10);
  };

  const [appoDate, setAppoDate] = useState(today());
  const [appoDateError, setAppoDateError] = useState(false);

  useEffect(
    () => () => {
      props.reset();
    },
    []
  );

  // useEffect(() => {
  //   if (props.success) {
  //     props.reset();
  //   }
  // }, [props.success]);

  const handleValidSubmit = (event, values) => {
    props.sendAppoRequest(values.firstName, values.lastName, values.ssn, values.email, values.phone, appoDate);
    event.preventDefault();
  };

  const onChangeAppoDate = evt => {
    const date = new Date(evt.target.value);
    const isBefore = date < new Date();

    if (isBefore) {
      setAppoDate(evt.target.value);
      setAppoDateError(true);
    } else {
      setAppoDate(evt.target.value);
      setAppoDateError(false);
    }
  };

  return (
    <section id="appointment" className="container appointment section-bg">
      <Row className="justify-content-center">
        <Col md="8">
          {/* <Translate contentKey="hospitalmsappfrontendApp.appointment.makeappointment">Make an appointment</Translate> */}
          <div className="section-title">
            <h2>{translate('hospitalmsappfrontendApp.appointment.makeappointment')}</h2>
            {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
          </div>
        </Col>
      </Row>
      <AvForm id="register-form" onValidSubmit={handleValidSubmit}>
        
        <Row className="justify-content-center">
          <Col md="6">
            <AvField
              name="firstName"
              label={translate('hospitalmsappfrontendApp.appointment.firstName')}
              placeholder={translate('hospitalmsappfrontendApp.appointment.firstName')}
              value={props.isPatient ? props.firstName : null}
              validate={{
                required: { value: true, errorMessage: translate('register.messages.validate.firstname.required') },
                minLength: { value: 1, errorMessage: translate('register.messages.validate.firstname.minlength') },
                maxLength: { value: 50, errorMessage: translate('register.messages.validate.firstname.maxlength') },
              }}
            />
          </Col>
          <Col md="6">
            <AvField
              name="lastName"
              label={translate('hospitalmsappfrontendApp.appointment.lastName')}
              placeholder={translate('hospitalmsappfrontendApp.appointment.lastName')}
              value={props.isPatient ? props.lastName : null}
              validate={{
                required: { value: true, errorMessage: translate('register.messages.validate.lastname.required') },
                minLength: { value: 1, errorMessage: translate('register.messages.validate.lastname.minlength') },
                maxLength: { value: 50, errorMessage: translate('register.messages.validate.lastname.maxlength') },
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <AvField
              name="ssn"
              label={translate('hospitalmsappfrontendApp.appointment.ssn')}
              placeholder="000-00-0000"
              value={props.isPatient ? props.ssn : null}
              validate={{
                required: { value: true, errorMessage: translate('register.messages.validate.ssn.required') },
                pattern: {
                  value: '/^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/',
                  errorMessage: 'Your SSN is invalid',
                },
              }}
            />
          </Col>

          <Col md="6">
            <AvField
              name="email"
              label={translate('global.form.email.label')}
              placeholder={translate('global.form.email.placeholder')}
              type="email"
              value={props.isPatient ? props.email : null}
              validate={{
                required: { value: true, errorMessage: translate('global.messages.validate.email.required') },
                minLength: { value: 1, errorMessage: translate('global.messages.validate.email.minlength') },
                maxLength: { value: 255, errorMessage: translate('global.messages.validate.email.maxlength') },
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <AvField
              name="phone"
              label={translate('hospitalmsappfrontendApp.appointment.phone')}
              placeholder="000-000-0000"
              validate={{
                required: { value: true, errorMessage: translate('register.messages.validate.phone.required') },
                pattern: {
                  value: '/^(1\\s|1|)?((\\(\\d{3}\\))|\\d{3})(\\-|\\s)?(\\d{3})(\\-|\\s)?(\\d{4})$/',
                  errorMessage: 'Phone number is invalid',
                  maxLength: { value: 12 },
                },
              }}
            />
          </Col>

          <Col md="6">
          <AvField
              name="appoDate"
              label={translate('hospitalmsappfrontendApp.appointment.appointmentDate')}
              placeholder={translate('hospitalmsappfrontendApp.appointment.appointmentDate')}
              type="date"
              value={appoDate}
              onChange={onChangeAppoDate}
              validate={{
                required: { value: true, errorMessage:"Required" },
                min: { value: new Date(), errorMessage: "Appointment date can not be past date!" },
              }}
            />


          </Col>
        </Row>
        <Row align="center" style={{ margin: 20 }}>
          <Col md="12">
            <button id="register-submit" className="appointment-btn scrollto" type="submit">
              <Translate contentKey="hospitalmsappfrontendApp.appointment.makeAppointment">Send</Translate>
            </button>
          </Col>
        </Row>
      </AvForm>

      {!props.userId ? (
        <Row>
          <Col style={{ textAlign: 'center' }}>
            <span style={{ fontSize: '20px' }}>
              If you want to see your appointment and test result information, you must register by using your SSN after appointment
              submission
            </span>
          </Col>
        </Row>
      ) : null}
    </section>
  );
};

const mapStateToProps = ({ authentication, locale, appoRequest }: IRootState) => ({
  currentLocale: locale.currentLocale,
  success: appoRequest.registrationSuccess,
  firstName: authentication.account.firstName,
  lastName: authentication.account.lastName,
  ssn: authentication.account.ssn,
  userId: authentication.account.id,
  email: authentication.account.email,
  isPatient: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PATIENT]),
});

const mapDispatchToProps = { sendAppoRequest, reset };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
